<template>
  <v-row>
    <!-- monthly invoice report (Tennyson) -->
    <v-col cols="6">
      <v-card outlined>
        <!-- title -->
        <v-card-title>Monthly Accounting Invoice Report</v-card-title>
        <!-- info -->
        <v-card-text class="text--primary">
          <p>
            Run report for Ryan Tennyson for all invoices created last month
            <strong>(Date Created: {{ this.lastMonth }}).</strong>
          </p>

          <ol>
            <li>Click Run Report Button</li>
            <li>Data will display in card to the right</li>
            <li>Click the copy to clipboard button</li>
            <li>
              Paste that data into the new browser tab that will automatically
              open
            </li>

            <li>
              Dowload and email CSV file and rename it to
              <code
                >sav_invoices_{{ this.lastMonth }} of
                {{ this.currentYear }}</code
              >
            </li>
            <li>
              Email CSV file to
              <code
                >accounting@silentauctionvacations.com,
                greg@silentauctionvacations.com</code
              >
            </li>
          </ol>
        </v-card-text>
        <!-- actions -->
        <v-card-actions>
          <v-spacer />
          <v-btn
            depressed
            small
            outlined
            color="primary"
            @click="runMonthlyInvoiceReport"
            >Run Report</v-btn
          > </v-card-actions
        >runMonthlyInvoiceReport
      </v-card>
    </v-col>
    <!-- output -->
    <v-col cols="6">
      <v-card outlined>
        <!-- title -->
        <v-card-title class="d-flex">
          <div>Report Data - {{ items.length }} Items</div>
          <v-spacer />
          <div v-if="items.length">
            <v-btn small depressed outlined color="pink" @click="copyText"
              >Copy to Clipboard</v-btn
            >
          </div>
        </v-card-title>
        <!-- output -->
        <v-card-text class="text--primary caption">
          <pre id="pre">{{ items }}</pre>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { portalsApp } from "@/firebase";
import moment from "moment";
export default {
  data: () => ({
    items: [],
  }),
  computed: {
    lastMonth() {
      return moment()
        .startOf("month")
        .subtract(1, "months")
        .format("MMMM");
    },
    currentYear() {
      return new Date().getFullYear();
    },
  },
  methods: {
    copyText() {
      // extracts text from pre tag
      // copies to native clipboard
      let pre = document.getElementById("pre");
      let text = pre.innerHTML;
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.handleSuccess("Copy Successful");
          setTimeout(() => {
            window.open("https://www.convertcsv.com/json-to-csv.htm", "_blank");
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    runMonthlyInvoiceReport() {
      // get all invoices that are not archived
      // push only items from last month of the current year
      portalsApp
        .firestore()
        .collection("eventReports")
        .where("archived", "==", false)
        .get()
        .then((snap) => {
          snap.docs.forEach((doc) => {
            let data = doc.data();
            if (!data.dateCreated) return;
            let dateCreatedYear = moment(data.dateCreated).format("YYYY");

            let dateCreatedMonth = moment(data.dateCreated).format("MMMM");

            if (
              // dateCreatedYear == this.currentYear &&
              // dateCreatedMonth == this.lastMonth
              dateCreatedYear == this.currentYear - 1 &&
              dateCreatedMonth == this.lastMonth
            ) {
              let item = {
                dateCreated: data.dateCreated,
                eventDate: data.eventDate,
                organizationName: data.organizationName,
                invoiceAmount: data.eventReportTotal,
                invoiceNumber: data.eventReportNumber,
              };
              this.items.push(item);
            }
          });
        });
    },
    activeCampaign() {
      // get all event reports with dates in Oct 2021 to add to post event active campaign automation
      portalsApp
        .firestore()
        .collection("eventReports")
        .where("archived", "==", false)
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            let data = doc.data();
            let eventDate = data.eventDate;
            if (
              eventDate.split("-")[0] == "2021" &&
              eventDate.split("-")[1] == "10"
            ) {
              this.items.push(data.eventContactEmail);
            }
          });
        });
    },
  },
};
</script>
