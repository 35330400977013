<template>
  <v-container>
    <!-- pageHeader -->
    <v-row>
      <v-col cols="8">
        <h1>Admin</h1>
      </v-col>
      <v-col cols="4" align="end">
        <v-btn
          color="primary"
          depressed
          @click="$router.go(-1)"
          :loading="$store.state.loading"
          :disabled="$store.state.loading"
        >
          <v-icon left>mdi-arrow-left</v-icon>
          Back</v-btn
        >
      </v-col>
    </v-row>
    <!-- pageHeader -->
    <!-- notifications -->
    <v-row>
      <v-col cols="12">
        <transition name="fade" mode="out-in" appear>
          <v-card
            outlined
            class="px-4 py-2 pb-4"
            :loading="$store.state.loading"
          >
            <div class="d-flex align-center mb-3">
              <h3 class="title">
                Notifications
                {{
                  adminNotifications && adminNotifications.length
                    ? adminNotifications.length
                    : ""
                }}
              </h3>
              <span class="inline-flex text-caption font-italic ml-12 mb-1"
                >sorted by date added (newest at top)</span
              >
              <v-spacer />
              <v-btn
                v-if="adminNotifications && adminNotifications.length"
                small
                outlined
                color="primary"
                @click="archiveAllNotifications"
                :loading="$store.state.loading"
                :disabled="$store.state.loading"
                >Archive All</v-btn
              >
            </div>

            <v-data-iterator
              :items="adminNotifications"
              item-key="id"
              no-data-text="All Notifications Archived"
              :loading="$store.state.loading"
              :items-per-page="6"
              :footer-props="{
                'items-per-page-options': [15, 25, 50, -1],
                'show-current-page': true,
              }"
            >
              <template v-slot:default="{ items }">
                <v-row dense>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    v-for="(item, index) in items"
                    :key="index"
                  >
                    <v-card
                      outlined
                      class="grey lighten-2 d-flex flex-column"
                      :loading="$store.state.loading"
                      height="100%"
                    >
                      <v-card-text class="text--primary flex-grow-1">
                        <p>{{ item.text }}</p>
                      </v-card-text>
                      <v-card-actions>
                        <div class="caption pl-2">
                          {{ formatDate(item.dateAdded) }}
                        </div>
                        <v-spacer />
                        <v-btn
                          :loading="$store.state.loading"
                          :disabled="$store.state.loading"
                          small
                          depressed
                          color="primary"
                          @click="archiveNotification(item)"
                          >archive</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
            </v-data-iterator>
          </v-card>
        </transition>
      </v-col>
    </v-row>

    <!-- notifications -->
    <!-- admin reminders -->
    <!-- <v-row>
      <v-col cols="12">
        <v-card outlined>
          <v-card-text class="text--primary">
            <v-row>
              <v-col cols="12" md="7">
                <h3 class="title">Admin Reminders</h3></v-col
              >
              <v-col cols="12" md="5">
                <v-text-field
                  class="ml-auto"
                  label="New Item"
                  dense
                  outlined
                  hint="press enter to add item"
                  @keydown.enter="onAddItem"
                  v-model.trim="newItem.text"
                  persistent-hint
                ></v-text-field
              ></v-col>
            </v-row>

            <v-data-table
              :item-class="itemClass"
              sort-by="text"
              class="mt-8"
              :headers="[{ text: 'Text', value: 'text' }]"
              :items="reminders.items"
              :items-per-page="-1"
              dense
              hide-default-footer
              @click:row="onDeleteItem"
            >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row> -->
    <!-- admin reminders -->
    <!-- monthly accounting invoice report -->
    <!-- <v-row>
      <v-col cols="12">
        <MonthlyAccountingInvoiceReport />
        </v-col
    ></v-row> -->
    <!-- monthly accounting invoice report -->
  </v-container>
</template>

<script>
import { portalsApp } from "@/firebase";
import firebase from "@/plugins/firebase";
import MonthlyAccountingInvoiceReport from "@/components/MonthlyAccountingInvoiceReport";
export default {
  components: { MonthlyAccountingInvoiceReport },
  firestore: {
    reminders: portalsApp
      .firestore()
      .collection("admin")
      .doc("reminders"),
    adminNotifications: portalsApp
      .firestore()
      .collection("adminNotifications")
      .where("archived", "==", false)
      .orderBy("dateAdded", "desc"),
  },

  data: () => ({
    reminders: "",
    adminNotifications: [],
    newItem: { text: "" },
  }),

  methods: {
    async archiveAllNotifications() {
      if (!this.adminNotifications || !this.adminNotifications.length) return;
      this.$store.state.loading = true;
      let total = this.adminNotifications.length;
      let count = 0;
      this.adminNotifications.forEach((i) => {
        if (i == null || i == undefined || !i.id) return;
        portalsApp
          .firestore()
          .collection("adminNotifications")
          .doc(i.id)
          .update({
            archived: true,
            dateArchived: new Date().toISOString().substring(0, 10),
          })
          .then(() => {
            count = count + 1;
            if (total == count) {
              this.$store.state.loading = false;
              this.$toast.success("All Notifications Archived");
            }
          });
      });
    },
    async archiveNotification(item) {
      if (item == null || item == undefined || !item.id) return;

      this.$store.state.loading = true;

      portalsApp
        .firestore()
        .collection("adminNotifications")
        .doc(item.id)
        .update({
          archived: true,
          dateArchived: new Date().toISOString().substring(0, 10),
        })
        .then(() => {
          this.$store.state.loading = false;
          this.$toast.success("Notification Archived", { timeout: 1500 });
        });
    },
    itemClass({ text }) {
      text = text.toLowerCase();
      if (text.includes("bob")) return "grey lighten-3";
      if (text.includes("ashley")) return "indigo lighten-4";
      if (text.includes("greg")) return "blue lighten-4";
      return "";
    },
    async onDeleteItem(item) {
      let items = this.reminders.items.filter((i) => i.text !== item.text);
      await portalsApp
        .firestore()
        .collection("admin")
        .doc("reminders")
        .update({ items });
    },
    async onAddItem() {
      if (!this.newItem.text) return;
      await portalsApp
        .firestore()
        .collection("admin")
        .doc("reminders")
        .update({
          items: firebase.firestore.FieldValue.arrayUnion(this.newItem),
        });
      this.newItem.text = "";
    },
  },
};
</script>

<style>
tr {
  cursor: pointer;
}
</style>
